<template>
  <div class="menu-list">
    <Action-panel :isReady="isPageReady">
      <Button-base
        :type="2"
        :text="translations.labels.button_add_menu"
        :hoverInfo="translations.labels.button_add_menu_hover"
        :onClick="newItem"
      />
    </Action-panel>

    <TableList
      :is-ready="isPageReady"
      :headers="headers"
      :items="model.filter(l => true)"
      :items-per-page="-1"
      sortable
      hideFooter
      @sorted="saveOrder"
      item-key="id"
      :searchVisible="false"
       />
  </div>
</template>

<script>
import { Utility } from "@/lib/Utility";
import Component, { mixins } from "vue-class-component";
import Container from "@/components/dom/Container";
import ActionPanel from "@/components/panel/ActionPanel";
import InnerPanel from "@/components/panel/InnerPanel";
import TablePanel from "@/components/panel/TablePanel";
import ButtonBase from "@/components/dom/ButtonBase";
import SvgIcon from "@/components/dom/SvgIcon";
import Round from "@/components/dom/Round";
import TableList from "@/components/table/TableList";
import Translations from "@/mixins/translations";

@Component({
   components: {
    ActionPanel,
    InnerPanel,
    TablePanel,
    ButtonBase,
    SvgIcon,
    Round,
    Container,
    TableList
  },
  inject: {
    uiService: "uiService",
    menuService: "menuService",
    eventService: "eventService",
    toastService: "toastService"
  }
})
export default class MenuList extends mixins(Translations) {
  isPageReady = false;
  model = [];
  events = [];
  search = "";
  ui = {};

  async prepareList () {
    let menus = await this.menuService.list();
    menus.forEach(menu => {
      const event = this.events.filter(e => e.id == menu.idEvent);
      if (event.length == 0) {
        menu.idEvent = null;
      } else {
        menu.idEvent = event[0].id;
      }
    });
    return menus;
  }

  async init () {
    try {
      this.ui = await this.uiService.ui("menu");
      this.events = await this.eventService.list();
      this.model = await this.prepareList();
      this.isPageReady = true;
    } catch (error) {
      // eslint-disable-next-line no-undef
      toastService.this.error(this.translations.errors.somethingWrong);
    }
  }

  toastSuccessLabel (isEnabled) {
    return isEnabled
      ? this.toastService.success(this.translations.success.menu_disable)
      : this.toastService.success(this.translations.success.menu_enable);
  }

  async toggleEnabled(idMenu, isEnabled) {
    const status = isEnabled ? "disable" : "enable";
    this.isPageReady = false;

    try {
      await this.menuService.setEnabled(idMenu, status);
      this.model = await this.prepareList();

      this.toastSuccessLabel(isEnabled);
      this.isPageReady = true;
    } catch (error) {
      this.isPageReady = true;
      // eslint-disable-next-line no-undef
      toastService.this.error(this.translations.errors.somethingWrong);
    }
  }

  newItem() {
    this.$router.push("/menu/new");
  }

  get headers() {
    return [
      {
        text: this.translations.labels.menu_table_code,
        value: "code"
      },
      {
        text: this.translations.labels.event_code,
        value: "eventCode"
      },
      {
        text: this.translations.labels.table_order,
        value: "priorityOrder"
      },
      {
        text: this.translations.labels.menu_table_menuFields,
        value: "menuFields",
        type: "link",
        icon: "mdi-link-variant",
        formatValue: (item) => {
          return `/menu-fields/${item.id}/list`;
        },
      },
      ...Utility.addIf(
        {
          text: this.translations.labels.table_clone,
          value: "clone",
          type: "link",
          formatValue: (item) => {
            return `/menu/clone/${item.id}`;
          },
          icon: "mdi-content-duplicate",
          buttonType: 2,
        },
        this.ui.duplicate
      ),
      {
        text: this.translations.labels.table_detail,
        value: "detail",
        type: "link",
        icon: "mdi-feature-search-outline",
        formatValue: (item) => {
          return `/menu/detail/${item.id}`;
        },
      },
      {
        text: this.translations.labels.table_edit,
        value: "edit",
        type: "link",
        formatValue: (item) => {
          return `/menu/edit/${item.id}`;
        },
        icon: "mdi-pencil",
        buttonType: 2,
      },
      {
        text: this.translations.labels.table_enable,
        value: "enable",
        type: "switch",
        itemValue: (item) => {
          return item.isEnabled;
        },
        onAction: (item) => {
          this.toggleEnabled(item.id, item.isEnabled);
        },
      }
    ];
  }

  mounted () {
    this.init();
    this.$store.commit(
      "SET_SECTION_TITLE",
      this.translations.pageTitles.menus_list
    );
  }

  async saveOrder(event) {
    const movedItem = this.model.splice(event.oldIndex, 1);
    this.model.splice(event.newIndex, 0, movedItem[0]);

    let newOrders = [];
    this.model.forEach((m, i) => {
      const index = i + 1;
      this.model[i].priorityOrder = index;
      newOrders.push({
        id: m.id,
        priorityOrder: index
      });
    });

    try {
      await this.menuService.saveOrder(newOrders);
    } catch (e) {
      this.toastService.error(this.translations.errors.somethingWrong);
    }
  }
}

</script>
<style lang="scss" scoped></style>
